import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    Switch,
    Typography,
} from '@mui/material';
import { Q } from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatabaseService from 'shared/db/services/Database.web';
import UserSettings from 'shared/db/services/UserSettings';
import { UserSettingsModel } from 'shared/types/UserSettings';

import { CollapsibleContainer } from '@/components';
import { useDBSyncContext } from '@/context/DBSyncContext';
import { useDatabase } from '@/hooks';
import Logger from '@/services/logger';

import { Props } from './types';

const EMAIL_TOPICS = [
    'appEmails',
    'marketingEmails',
    'rsvpAppointmentsUpdates',
];

function SubscriptionsAndNotificationsSection({
    inAppSettings,
    emailSettings,
}: Props) {
    const { t } = useTranslation();
    const { getDatabase } = useDatabase();
    const { isSyncInProgress } = useDBSyncContext();

    const userSettingsService = useMemo(
        () =>
            new UserSettings({
                database: getDatabase(),
                logDBAction: Logger.logRecordActivity,
            }),
        [getDatabase],
    );

    const updateValue = useCallback(
        async (id, value) => {
            await userSettingsService.setIsEnabled(id, value);
        },
        [userSettingsService],
    );

    const getUserSettingSwitch = useCallback(
        (setting: UserSettingsModel) => {
            return (
                <FormControlLabel
                    key={setting.id}
                    disabled={isSyncInProgress}
                    checked={setting.enabled}
                    labelPlacement="start"
                    style={{
                        justifyContent: 'space-between',
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    control={
                        <Switch
                            onChange={(_, checked) =>
                                updateValue(setting.id, checked)
                            }
                            value={setting.enabled}
                        />
                    }
                    data-test-id="InvoicesPage-FiltersPanel-ApplySwitch"
                    label={setting.description}
                />
            );
        },
        [isSyncInProgress, updateValue],
    );

    return (
        <CollapsibleContainer
            title={t('Options:button:subscriptions_notifications')}
            testID="SettingsPage-SubscriptionsAndNotifications"
        >
            <Typography
                component="h5"
                fontSize={15}
                fontWeight={'700'}
                sx={{ mt: 3 }}
            >
                {t('Options:subscriptions:in_app_title')}
            </Typography>
            <Typography component="h6" fontSize={13} sx={{ mt: 2, mb: 3 }}>
                {t('Options:subscriptions:subtitle')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {inAppSettings.map((setting) => getUserSettingSwitch(setting))}
            </Box>
            {emailSettings.length > 0 ? (
                <>
                    <Typography
                        component="h5"
                        fontSize={15}
                        fontWeight={'700'}
                        sx={{ mt: 4 }}
                    >
                        {t('Options:subscriptions:email_title')}
                    </Typography>
                    <Typography
                        component="h6"
                        fontSize={13}
                        sx={{ mt: 2, mb: 3 }}
                    >
                        {t('Options:subscriptions:subtitle_email')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        {emailSettings.map((setting) =>
                            getUserSettingSwitch(setting),
                        )}
                    </Box>
                </>
            ) : null}
            <div className="py-4">
                <Alert severity="info">
                    {t('Options:button:equinet')}
                    <Button
                        className="underline"
                        size="small"
                        color="inherit"
                        target="_blank"
                        href={t('Options:terms_and_conditions_url')}
                        rel="noopener noreferrer"
                    >
                        {t('Options:button:terms_of_service')}
                    </Button>{' '}
                    and{' '}
                    <Button
                        className="underline"
                        size="small"
                        color="inherit"
                        target="_blank"
                        href={t('Options:privacy_policy_url')}
                        rel="noopener noreferrer"
                    >
                        {t('Options:button:privacy_policy')}
                    </Button>
                </Alert>
            </div>
        </CollapsibleContainer>
    );
}

const enhance = withObservables([], () => {
    const database = DatabaseService.getDatabase();

    return {
        inAppSettings: database.collections
            .get('user_settings')
            .query(Q.where('topic', Q.notIn(['system', ...EMAIL_TOPICS])))
            .observeWithColumns(['enabled']),
        emailSettings: database.collections
            .get('user_settings')
            .query(
                Q.and(
                    Q.where('topic', Q.notEq('system')),
                    Q.where('topic', Q.oneOf(EMAIL_TOPICS)),
                ),
            )
            .observeWithColumns(['enabled']),
    };
});

export default enhance(SubscriptionsAndNotificationsSection);
